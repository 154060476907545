import * as React from "react";
import TopBar from "../components/TopBar";
import Slider from "@ant-design/react-slick";
import Homestay1 from "../assets/homestay1.jpeg";
import Homestay2 from "../assets/homestay2.jpeg";
import Homestay3 from "../assets/homestay3.jpeg";
import ServiceLabel from "../assets/services.png";
import LocationLabel from "../assets/location.png";
import FeedbackLabel from "../assets/feedback.png";
import ActivitiesLabel from "../assets/activities.png";
import CheckLabel from "../assets/check.png";
import Lacha from "../assets/lacha.png";
// import LachaMini from "../assets/lacha_mini.png";
import IntroLabel from "../assets/intro.png";
import BeachWalk from "../assets/beach_walk.jpg";
import BeachWatchCompany from "../assets/beach_watch_company.jpg";
import DrinkLacha from "../assets/drink_lacha.jpg";
import Farm from "../assets/farm.jpg";
import FashionShow from "../assets/fashion_show.jpg";
import FlowerWatchCompany from "../assets/flower_watch_company.jpg";
import ForceRelax from "../assets/force_relax.jpg";
import FreeBreads from "../assets/free_breads.jpg";
import FreeBreakfast from "../assets/free_breakfast.jpg";
import HelpSetWallpaper from "../assets/help_set_wallpaper.jpg";
import HelpSneak from "../assets/help_sneak.jpg";
import HelpWork from "../assets/help_work.jpg";
import KeepInFridge from "../assets/keep_in_fridge.jpg";
import LiftService from "../assets/lift_service.jpg";
import MakeFriends from "../assets/make_friends.jpg";
import MorningCall from "../assets/morning_call.jpg";
import ShareCookies from "../assets/share_cookies.jpg";
import TeachWushu from "../assets/teach_wushu.jpg";
import VisitZoo from "../assets/visit_zoo.jpg";
import WaitForFlight from "../assets/wait_for_flight3.jpg";
import SendToAirport from "../assets/send_to_airport.jpg";
import Birthday from "../assets/birthday.jpg";
import Malay from "../assets/malay.jpg";
import Fetch from "../assets/fetch.jpg";
import GroceryShopping from "../assets/grocery_shopping.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./HomePage.scss";
import Snowfall from "react-snowfall";
import { InstagramEmbed } from "react-social-media-embed";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
  arrows: true,
  pauseOnFocus: true,
};

const settingsSlow = {
  dots: false,
  infinite: true,
  speed: 500,
  autoplaySpeed: 15000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  pauseOnHover: true,
  arrows: true,
  centerPadding: 12,
  pauseOnFocus: true,
};

const activities = [
  {
    img: BeachWalk,
    title: "陪伴旅客到海邊散步",
  },
  {
    img: Birthday,
    title: "剛好遇到旅客的生日，於是舉辦了驚喜慶生Party🎉",
  },
  {
    img: BeachWatchCompany,
    title: "帶領旅客到海邊觀賞日落",
  },
  {
    img: DrinkLacha,
    title: "與旅客一起喝杯拉茶，實現同類相食",
  },
  {
    img: Farm,
    title: "民宿專屬農場，鼓勵旅客吃菜",
  },
  {
    img: FashionShow,
    title: "拉茶時裝秀",
  },
  {
    img: FlowerWatchCompany,
    title: "花海免費入場，無限花花供觀賞",
  },
  {
    img: FreeBreads,
    title: "免費麵包，應該是還沒過期",
  },
  {
    img: FreeBreakfast,
    title: "免費早餐，應該不是昨天吃剩的",
  },
  {
    img: HelpSneak,
    title: "偷看旅客在房裡做什麼",
  },
  {
    img: HelpWork,
    title: "協助旅客完成工作",
  },
  {
    img: ShareCookies,
    title: "免費請吃餅乾",
  },
  {
    img: VisitZoo,
    title: "免費陪伴參觀動物園",
  },
];

const services = [
  {
    img: WaitForFlight,
    title: "免費接機服務",
  },
  {
    img: LiftService,
    title: "電梯小狗按樓服務",
  },
  {
    img: ForceRelax,
    title: "強制放鬆，享受假期",
  },
  {
    img: MorningCall,
    title: "免費叫醒服務",
  },
  {
    img: KeepInFridge,
    title: "協助把食物放進冰箱",
  },
  {
    img: TeachWushu,
    title: "七十三代拉茶流派豆腐拳課程",
  },
  {
    img: MakeFriends,
    title: "免費交各式動物朋友",
  },
  {
    img: HelpSetWallpaper,
    title: "手機壁紙免費下載並協助設定",
  },
  {
    img: SendToAirport,
    title: "免費送機服務",
  },
  {
    img: Malay,
    title: "從零開始的馬來文教學",
  },
  {
    img: Fetch,
    title: "免費接送服務",
  },
  {
    img: GroceryShopping,
    title: "購物 >> 從看看，買賣，甚至「爆買」都一路相陪",
  },
];

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sliderPosition: -38,
    };
    this.bannerSlider = React.createRef();
    this.bannerSlider2 = React.createRef();
    this.bannerSlider3 = React.createRef();
    this.slideTo = this.slideTo.bind(this);
    // shuffle services and assign to this.service
    // this.services = services.sort(() => Math.random() - 0.5);
    this.services = services;
    this.feedbacks = [
      {
        traveller: "徐* 小姐",
        content:
          "民宿備品完備、排列規律，主人親切熱情，還有送機服務。寵物活潑有個性！另外，民宿還有提供套裝行程，強烈建議選擇。",
      },
      {
        traveller: "許** 小姐",
        content: "溫馨、整潔，還有當家寵物陪睡，讓人感到非常舒適幸福❤️",
      },
      {
        traveller: "鄭** 小姐",
        content: "看起來很乾淨！我都想在這裡置產了！",
      },
      {
        traveller: "**華 小姐",
        content:
          "地理位置相當優越，距捷運站約步行8分鐘。附近餐飲、超市和各種小商店眾多，生活機能便利。因為是新建大樓，整體環境相當整潔。六樓有露天游泳池提供住戶使用。本大樓2-5層是停車場，對住戶來説相當便利。樓層無遮擋，採光、通風很好。該民宿的床最值得推薦，是雙人床但卻不會互相干擾，既有單人床的優點，又有雙人床的舒適。",
      },
      {
        traveller: "**勝 先生",
        content:
          "民宿位於高樓層，走廊面對湖景風光視野明媚,一樓就有很多美食商家鄰棟就是大賣場,離大家樂茶室及米其林興記肉骨茶及捷運站約8分鐘路程, 生活機能優, 女主人來自台灣美食客, 推薦很多適合台灣人口味美食, 和藹親切讓大馬之行倍感溫馨！",
      },
      {
        traveller: "**君 小姐",
        content:
          "很喜歡拉茶民宿，環境乾淨整潔，廚房設備很新，有提供基本鍋具、碗筷。民宿隔壁就是賣場，可以買當地生鮮回家烹煮。有提供免費洗衣設備，加上陽光充足，衣服一下就乾，可帶少量衣服入住即可。\n萌寵拉茶很可愛，帶我們去吃各式天花板美食，從肉骨茶、加耶吐司+生熟蛋、剉冰、辣椒板麵…都是以前沒吃過的味道。時不時看拉茶賣萌，增加不少旅行的樂趣！",
      },
      {
        traveller: "**鴻 先生",
        content: "來了好多次，沒有一次是失望的，好吃好住好睡好風水",
      },
    ];
  }

  slideTo(e) {
    if (this.bannerSlider) {
      this.bannerSlider.current.slickGoTo(e);
    }
  }

  star() {
    return (
      <svg
        width="28"
        height="28"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.00002 14.275L4.85001 16.775C4.66668 16.8917 4.47501 16.9417 4.27501 16.925C4.07501 16.9083 3.90001 16.8417 3.75001 16.725C3.60001 16.6083 3.48335 16.4625 3.40002 16.2875C3.31668 16.1125 3.30002 15.9167 3.35002 15.7L4.45001 10.975L0.775015 7.8C0.608348 7.65 0.504182 7.47916 0.462515 7.2875C0.420848 7.09583 0.433348 6.90833 0.500015 6.725C0.566682 6.54166 0.666682 6.39166 0.800015 6.275C0.933348 6.15833 1.11668 6.08333 1.35002 6.05L6.20001 5.625L8.07502 1.175C8.15835 0.974997 8.28752 0.824997 8.46252 0.724997C8.63752 0.624997 8.81668 0.574997 9.00002 0.574997C9.18335 0.574997 9.36252 0.624997 9.53752 0.724997C9.71252 0.824997 9.84168 0.974997 9.92502 1.175L11.8 5.625L16.65 6.05C16.8833 6.08333 17.0667 6.15833 17.2 6.275C17.3333 6.39166 17.4333 6.54166 17.5 6.725C17.5667 6.90833 17.5792 7.09583 17.5375 7.2875C17.4958 7.47916 17.3917 7.65 17.225 7.8L13.55 10.975L14.65 15.7C14.7 15.9167 14.6833 16.1125 14.6 16.2875C14.5167 16.4625 14.4 16.6083 14.25 16.725C14.1 16.8417 13.925 16.9083 13.725 16.925C13.525 16.9417 13.3333 16.8917 13.15 16.775L9.00002 14.275Z"
          fill="#F0D6AC"
        />
      </svg>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            pointerEvents: "none",
            zIndex: -5,
          }}>
          <Snowfall snowflakeCount={100} />
        </div>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            padding: 16,
            zIndex: -1,
          }}>
          <img
            src={Lacha}
            style={{
              width: 128,
              height: 128,
              zIndex: -1,
            }}
            alt=""
          />
        </div>
        <div
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            padding: 16,
            zIndex: -1,
          }}>
          <img
            src={Lacha}
            style={{
              width: 128,
              height: 128,
              transform: "scaleX(-1)",
              zIndex: -1,
            }}
            alt=""
          />
        </div>
        <TopBar />
        <div style={{ height: 112 }}></div>
        <div style={{ padding: 16 }}>
          <img className="label" src={IntroLabel} alt="" />
          <p
            style={{
              padding: 16,
              background: "#FFFFFF80",
              borderRadius: 8,
              zIndex: 10,
              backdropFilter: "blur(4px)",
              "-webkit-backdrop-filter": "blur(4px)",
            }}>
            這是一間位在馬來西亞的溫馨民宿，由馬來西亞人與台灣人夫妻共同成立，民宿裡有二狗一鴨，友善、溫和且熱情地招待來自各地的旅客，一同探索馬來西亞的美食與生活。希望旅客都能在此體驗療癒身心的「大馬式慢活生活」。
          </p>
        </div>

        <img className="label" src={LocationLabel} alt="" />
        <div style={{ padding: 16 }}>
          {" "}
          <p
            style={{
              maxWidth: 512,
              padding: 12,
              boxSizing: "border-box",
              background: "#FFFFFF80",
              zIndex: 10,
              borderRadius: 8,
              backdropFilter: "blur(4px)",
              "-webkit-backdrop-filter": "blur(4px)",
            }}>
            Fortune Centra Residences, Jalan Metro Perdana 6, Taman Usahawan
            Kepong, 52100 Kuala Lumpur, Federal Territory of Kuala Lumpur
          </p>
        </div>

        <div
          style={{
            width: "100%",
            maxWidth: 720,
            position: "relative",
            padding: 32,
            borderRadius: 8,
            boxSizing: "border-box",
          }}>
          <Slider
            ref={this.bannerSlider}
            {...settings}
            beforeChange={(e, f) => {
              if (f === 0) {
                this.setState({ sliderPosition: -38 });
                return;
              }
              if (f === 1) {
                this.setState({ sliderPosition: 0 });
                return;
              }
              if (f === 2) {
                this.setState({ sliderPosition: 38 });
                return;
              }
            }}>
            <img className="banner-image" src={Homestay1} alt="" />
            <img className="banner-image" src={Homestay2} alt="" />
            <img className="banner-image" src={Homestay3} alt="" />
          </Slider>
          {/* <div className="slider-controller">
            <div
              className="slider-controller-dot-wrapper"
              onClick={() => {
                this.slideTo(0);
              }}>
              <div
                id="slider-controller-page1-btn"
                className="slider-controller-dot"></div>
            </div>
            <div
              className="slider-controller-dot-wrapper"
              onClick={() => {
                this.slideTo(1);
              }}>
              <div
                id="slider-controller-page1-btn"
                className="slider-controller-dot"></div>
            </div>
            <div
              className="slider-controller-dot-wrapper"
              onClick={() => {
                this.slideTo(2);
              }}>
              <div
                id="slider-controller-page1-btn"
                className="slider-controller-dot"></div>
            </div>
          </div>

          <div className="slider-controller noclick">
            <div className="slider-selected-wrapper">
              <div
                className="slider-selected-circle"
                style={{
                  transform: `translateY(${this.state.sliderPosition}px)`,
                }}></div>
            </div>
          </div> */}
        </div>

        <img className="label" src={ServiceLabel} alt="" />
        <div
          style={{
            width: "100%",
            maxWidth: 720,
            position: "relative",
            padding: 32,
            borderRadius: 8,
            boxSizing: "border-box",
          }}>
          <Slider ref={this.bannerSlider2} {...settings}>
            {services.map((item, index) => {
              return (
                <div>
                  <div
                    id="services"
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "bottom",
                    }}>
                    <img className="service-image" src={item.img} alt="" />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        fontWeight: "bold",
                        fontSize: 20,
                        padding: 8,
                        boxSizing: "border-box",
                        color: "#fff",
                        backgroundColor: "rgba(0,0,0,0.65)",
                        borderRadius: 8,
                      }}>
                      <p
                        style={{
                          margin: 0,
                        }}>
                        {item.title}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <img className="label" src={ActivitiesLabel} alt="" />
        <div
          style={{
            width: "100%",
            maxWidth: 720,
            position: "relative",
            padding: 32,
            borderRadius: 8,
            boxSizing: "border-box",
          }}>
          <Slider ref={this.bannerSlider2} {...settings}>
            {activities.map((item, index) => {
              return (
                <div>
                  <div
                    id="services"
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "bottom",
                    }}>
                    <img className="service-image" src={item.img} alt="" />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        fontWeight: "bold",
                        fontSize: 20,
                        padding: 8,
                        boxSizing: "border-box",
                        color: "#fff",
                        backgroundColor: "rgba(0,0,0,0.75)",
                        borderRadius: 8,
                      }}>
                      <p
                        style={{
                          margin: 0,
                        }}>
                        {item.title}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <img className="label" src={FeedbackLabel} alt="" />
        <div
          style={{
            width: "100%",
            maxWidth: 720,
            position: "relative",
            padding: 32,
            borderRadius: 8,
            boxSizing: "border-box",
          }}>
          <Slider ref={this.bannerSlider3} {...settingsSlow}>
            {this.feedbacks.map((item, index) => {
              return (
                <div>
                  <div
                    id="feedbacks"
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "bottom",
                    }}>
                    <div
                      style={{
                        bottom: 0,
                        width: "100%",
                        fontWeight: "bold",
                        fontSize: 16,
                        padding: 16,
                        boxSizing: "border-box",
                        color: "#fff",
                        backgroundColor: "rgba(0,0,0,0.5)",
                        borderRadius: 8,
                      }}>
                      <p
                        style={{
                          margin: 0,
                          textAlign: "left",
                          whiteSpace: "pre-wrap",
                        }}>
                        {item.content}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          marginTop: 8,
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}>
                        <div>
                          {this.star()}
                          {this.star()}
                          {this.star()}
                          {this.star()}
                          {this.star()}
                        </div>
                        <p
                          style={{
                            margin: 0,
                            textAlign: "right",
                            fontSize: 14,
                            opacity: 0.8,
                          }}>
                          {item.traveller}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <img className="label" src={CheckLabel} alt="" />
        <div style={{ padding: 16 }}>
          <InstagramEmbed
            url="https://www.instagram.com/p/Cxu3K3RRBsU/"
            width="100%"
          />
        </div>
        <div style={{ height: 128 }}></div>
      </React.Fragment>
    );
  }
}

export default HomePage;
