"use client";
import React from "react";
import "./TopBar.scss";

import Logo from "../assets/logo.png";

class TopBar extends React.Component {
  render() {
    return (
      <div className={`top-bar-wrapper`}>
        <div className={`top-bar-backdrop`}>
          <div className="left">
            <a href="/">
              <img src={Logo} alt="Pixels Perfect" className="logo" />
            </a>
          </div>
          <div className="nav-button">
            <div className="right">
              <p>至尊貴賓限定</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
